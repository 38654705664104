<template>
  <div>
    <page-title :heading="$t('generic.lang_reservation')" :icon=icon :subheading="$t('generic.lang_reservation')"
    ></page-title>
    <div class="app-main__inner">
      <today-reservation-component/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import TodayReservationComponent from "@/components/reservation/reservations/TodayReservationComponent";


export default {
  name: 'TodayReservation',
  components: {
    TodayReservationComponent,
    PageTitle,
  },
  data: () => ({
    icon: 'pe-7s-ribbon icon-gradient bg-tempting-azure',
  })
}
</script>
