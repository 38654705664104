<template>
  <v-container fluid>
    <v-card outlined>
      <v-card-title class="primary--text border-bottom">
        {{ this.$t('generic.lang_reservation') + ': ' + new Date().toLocaleDateString() }}
      </v-card-title>
      <v-card-text class="px-0">
        <v-row class="px-0 pb-4">
          <v-col cols="12">
            <v-row>
              <v-col sm="12" class="pa-0 ma-0">
                <div style="height: 0 !important; visibility: hidden !important;">
                  <v-calendar
                      ref="calendar"
                      v-model="requested_date"
                      color="primary"
                      type="day"
                  ></v-calendar>
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <div
                    class="pa-0 px-0 ma-0"
                >
                  <v-btn
                      class="mr-0"
                      color="grey darken-2"
                      outlined
                      @click="setToday"
                  >
                    {{ $t('generic.lang_today') }}
                  </v-btn>
                  <v-btn
                      color="grey darken-2"
                      fab
                      icon
                      text
                      @click="$refs.calendar.prev()"
                  >
                    <v-icon large>
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>

                  <v-btn class="elevation-0 text-black border" color="primary" fab small @click="picker=true">
                    {{ new Date(requested_date).getDate() }}
                  </v-btn>


                  <v-btn
                      color="grey darken-2"
                      fab
                      icon
                      small
                      @click="$refs.calendar.next()"
                  >
                    <v-icon large>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                  <strong v-if="$refs.calendar" class="font-weight-bolder">
                    {{ $refs.calendar.title }}
                  </strong>
                </div>
              </v-col>

              <v-col cols="12" sm="6" class="text-right">
                <v-btn :disabled="!(Array.isArray(selected_reservations) && selected_reservations.length > 0)"
                       @click="pdf" class="mx-auto mr-5" depressed color="warning">
                  {{ $t('erp.lang_print') }}
                </v-btn>
              </v-col>
            </v-row>

          </v-col>
          <!-- filters -->
          <v-col cols="12" class="d-flex px-6">
            <v-row class="pt-0 mt-0">

              <!-- search filter -->
              <v-col cols="12" md="6" sm="6">
                <b-form-input v-model="search" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :placeholder="$t('customers.lang_nav_searchcust')+' ...'"
                              size="lg" @focus="showTouchKeyboard"/>
              </v-col>
              <!-- types filter -->
              <v-col cols="12" md="6" sm="6">
                <b-form-select v-model="type" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" :label-field="$t('generic.lang_constReservationType')" :placeholder="$t('generic.lang_type')" size="lg">
                  <b-form-select-option :value="-1">{{ $t('generic.lang_allTypes') }}</b-form-select-option>
                  <b-form-select-option v-for="t in this.types" :key="t.uuid" :value="t.uuid">{{ t.name }}
                  </b-form-select-option>
                </b-form-select>
              </v-col>

            </v-row>
          </v-col>
          <v-col cols="12">
            <v-data-table :mobile-breakpoint="1281" item-key="uuid" selectable-key="uuid"
                          v-model="selected_reservations"
                          show-select
                          :search.sync="this.search"
                          :loading="loadData || loading"
                          :headers="this.headers"
                          :items="this.reservations">
              <template v-slot:item.name="{item}">
                <v-list-item>
                  <v-list-item-avatar color="primary">
                    <v-icon color="white">person</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="primary--text font-weight-bold">{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.phone }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:item.resType_name="{item}">
                <v-chip :color="item.resType_obj.color" class="rounded-lg">
                  <span class="white--text">{{ item.resType_name }}</span>
                </v-chip>
              </template>


              <template v-slot:item.tables="{item}">
                <div>
                  {{ item.tables.map((elt) => elt.name).join(' , ') }}
                </div>
              </template>


              <template v-slot:item.prepared="{item}">
                <div>
                  <v-switch :disabled="( item.deleted === 1)" @change="setPrepared($event , item)" :value="1"
                            v-model="item.prepared"/>
                </div>
              </template>

              <template v-slot:item.action="{item}">
                <v-btn text @click="print(item)" fab>
                  <font-awesome-icon class="warning--text" size="2x"
                                     :icon="['fal','print']"/>
                </v-btn>

                <v-btn :disabled="( item.deleted === 1)" text @click="initOrder(item)"
                       fab>
                  <font-awesome-icon :class="loading  ||item.deleted === 1? 'text-muted' : 'primary--text'" size="2x"
                                     :icon="['fal','paper-plane']"/>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>

          <!-- reservations datatable -->
        </v-row>
      </v-card-text>
    </v-card>

    <reservation-plan-tables-component v-if="this.selected_reservation" v-model="tables_dialog"
                                       :reservation="this.selected_reservation.uuid"
                                       :persons="this.selected_reservation.person"
                                       :date="this.selected_reservation.date" :time="this.selected_reservation.time"
                                       :reservation_type="this.selected_reservation.resType"/>

    <reservation-pdf-component ref="pdf" :reservations="this.selected_reservations" :date="new Date(this.requested_date)"/>

    <print-reservation-dialog v-if="this.print_dialog" v-model="print_dialog" :default_data="this.printData"
                              v-bind:dialog="this.print_dialog"
                              @close_printDialog="close_printDialog()"
    />

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"/>
    </div>


    <!-- date picker dialog -->
    <v-dialog v-model="picker" max-width="400px" scrollable>
      <v-card align="center" class="pa-0 ma-0">
        <v-card-title class="pa-0 px-0 ma-0">
          <v-spacer/>
          <v-btn icon @click="picker=false">
            <v-icon color="error">
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <v-date-picker
              v-model="requested_date"
              event-color="green lighten-1"
              width="100%"
          ></v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>

    <PrintOrderbon ref="printOrderBon" @reprintFinished="orderbonReprintFinished"
                   @cancelReprint="orderbonCancelReprint"></PrintOrderbon>
  </v-container>
</template>

<script>
/**
 * import plugins
 */
import mixin from "@/mixins/KeyboardMixIns";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faPaperPlane, faPrint} from '@fortawesome/pro-light-svg-icons'
import {mapGetters, mapState} from 'vuex';
import ReservationPlanTablesComponent from "@/components/pos/ReservationPlanTablesComponent";
import ReservationPdfComponent from "@/components/reservation/reservations/ReservationPdfComponent";
import PrintReservationDialog from "@/components/reservation/reservations/PrintReservationDialog";


import PrintOrderbon from "@/mixins/pos/PrintOrderbon";

library.add(faPrint, faPaperPlane)

export default {
  name: "TodayReservationComponent",
  components: {
    ReservationPdfComponent,
    ReservationPlanTablesComponent,
    PrintReservationDialog,
    'font-awesome-icon': FontAwesomeIcon,
    PrintOrderbon,
  },
  mixins: [mixin],
  data: () => ({
    current_date: new Date(),
    types: [],
    type: -1,
    print_dialog: false,
    types_load: false,
    reservations: [],
    loadData: false,
    search: "",
    loading: false,
    selected_reservation: null,
    tables_dialog: false,
    selected_reservations: [],
    printData: null,
    requested_date: "",
    picker: false,
  }),
  watch: {
    search(val) {
      val & this.loadRes();
    },
    type(val) {
      val & this.loadRes();
    },
    reservations(val) {
      val & (this.selected_reservations = [])
    },
    requested_date(val) {
      val & this.loadRes();
    }
  },
  computed: {
    ...mapState([
      'pos'
    ]),
    ...mapGetters({
      openItems: 'pos/gastro/openItems',
      orderedItems: 'pos/gastro/orderedItems',
      fiscalClient: 'tse/fiscalClient',
      user: 'user/user'
    }),
    headers: function () {
      return [
        {
          text: this.$t('customers.lang_customers'),
          value: 'name',
          sortable: false
        },
        {
          text: this.$t('generic.lang_type'),
          value: 'resType_name',
          sortable: false,
        },
        {
          text: this.$t('generic.lang_noOfPersons'),
          value: 'person',
          sortable: false,
        },
        {
          text: this.$t('generic.lang_time'),
          value: 'time',
          width: "200"
        },
        {
          text: this.$t('generic.lang_till'),
          value: 'end_at',
          width: "200"
        },
        {
          text: this.$t('generic.lang_table'),
          value: 'tables',
          sortable: false,
        },
        {
          text: this.$t('generic.lang_prepared'),
          value: 'prepared',
          sortable: false,
        },
        {
          text: "",
          value: 'action',
          align: "right",
          sortable: false,
          width: "200"
        },
      ]
    },
  },
  methods: {
    setPrepared(val, reservation) {
      if (val) {
        this.loading = true;
        this.axios.post(ENDPOINTS.RESERVATION.RESERVATIONS.UPDATE_PREPARATION,
            {
              uuid: reservation.uuid,
              prepared: val,
              prepared_by: this.user.userID
            }).then((res) => {
          if (res.data.STATUS === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_success'),
              color: "success"
            });

          } else if (res.data.STATUS === "FAILED") {
            Events.$emit("showSnackbar", {
              message: res.data.msg,
              color: "error"
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }).finally(() => {
          this.loading = false;
        })
      }
    },
    setToday() {
      let dt = new Date();
      this.requested_date = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    pdf() {
      this.$refs.pdf.generatePDF();
    },
    print(reservation) {

      this.print_dialog = true;
      this.printData = reservation;


      //filter items form Items state using ware_id in 'items' array then u can print the receipt
    },
    close_printDialog() {
      this.print_dialog = false;
      this.printData = null;
    },
    /**
     * get all available reservation types
     */
    loadTypes() {
      this.types_load = true;
      this.types = [];
      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.TYPES.GET).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.types = res.data.types;

        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.types_load = false;
      })
    },
    /**
     * get all reservations for today
     */
    loadRes() {
      this.loadData = true;
      this.axios.post(ENDPOINTS.RESERVATION.RESERVATIONS.GET
          + '?type=' + this.type + '&day=' + this.requested_date + '&search=' + this.search + '&deleted=' + false
      ).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.reservations = [];
          this.reservations = res.data.reservations;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadData = false;
      })
    },
    selectTable(table) {
      this.$store.dispatch("pos/gastro/setTable", {
        posType: "gastro",
        table: table
      }).then(() => {
        //this.$router.push("/pos/gastro");
      });
    },
    /**
     * filter available tables and reserved tables (has already an order)
     * @param tables
     * @returns {Promise<unknown>}
     */
    async checkAvailableTables(tables) {
      return new Promise(((resolve, reject) => {
        //check if no table provided
        if (!Array.isArray(tables)) reject(false);
        this.loading = true;
        //request for tables that already have orders
        this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.INFO).then((res) => {//todo: add loader
          if (res.data.success) {
            const all_locked_tables = res.data.tables;
            const unavailable_tables = all_locked_tables.filter(
                (elt) => tables.includes(elt.name)).map((elt) => elt.name
            );
            const available_tables = tables.filter((table) => !unavailable_tables.includes(table));

            //no table available
            if (Array.isArray(available_tables) && available_tables.length <= 0) {
              this.loading = false;
              reject(this.$t('generic.lang_reservedTablesAreUnavailableCurrently'));
            }

            //resolve promise with available and unavailable tables then the user can choice to continue with only available ones
            this.loading = false;
            resolve({
              available: available_tables,
              unavailable: unavailable_tables
            });
          } else {
            this.loading = false;
            reject(this.$t('generic.lang_errorOccurred'));
          }


        }).catch((err) => {
          this.loading = false;
          reject(err.message);
        }).finally(() => {
          this.loading = false;
        })
      }))
    },
    async setCustomer(id) {
      await this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
        customerID: id
      }).then((res) => {
        if (res.data.success) {
          this.$store.dispatch("pos/setCustomer", {
            posType: 'gastro',
            customer: res.data.customer,
            setCustomerOnly:true,
          })
        }
      }).catch(() => {

      });
    },
    async bookItems(items) {
      new Promise((resolve => {
        items.forEach(async (item) => {
          await this.$store.dispatch("pos/gastro/bookItem", item);
        })
        resolve(true);
      }))
    },
    async setGastroOrder(reservation, table) {
      let tmp_items = [];

      if (Array.isArray(reservation.items) && reservation.items.length > 0) tmp_items = reservation.items.map((item) => item.ware_id);
      //filter items from vuex

      let items = this.items.filter((elt) => tmp_items.includes(elt.id))
          .map((elt) => {
            let search = reservation.items.find((item) => item.ware_id === elt.id);
            if (search)
              elt.weight = search.quantity
            elt.mealSizeID = 0;
            elt.discount = 0;
            elt.course = null;
            elt.individualBookingNo = 0;
            elt.serialNo = 0;
            elt.originalSellPrice = elt.sellPrice;
            elt.selectedExtras = []; //TODO: we have to delete this line the user can select extras from reservation
            return elt;
          })

      //set default party
      table.parties = [{
        name: reservation.name
      }]

      this.selectTable(table);

      //set this customer as current party
      await this.$store.dispatch("pos/gastro/changeParty", {
        posType: "gastro",
        party: this.pos.gastro.party
      });

      //set order freeText
      this.$store.commit('pos/gastro/setFreetext', reservation.note);

      //book items
      await this.bookItems(items).then(async () => {


        //set order as reservation
        this.$store.commit("pos/gastro/setOrderAsReservation", true);
        this.$store.commit("pos/gastro/setReservation", reservation.uuid);


        //set this customer as current party
        await this.$store.dispatch("pos/gastro/changeParty", {
          posType: "gastro",
          party: this.pos.gastro.party
        });


        //set customer
        await this.setCustomer(reservation.customer_id);
        //make order
        await this.orderItems().then(() => {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_orderedSuccessfully'),
            color: "success"
          });

          //clear reservation flag and uuid
          this.$store.commit("pos/gastro/setOrderAsReservation", false);
          this.$store.commit("pos/gastro/setReservation", null);

          //clear customer
          this.$store.dispatch("pos/setCustomer", {
            posType: 'gastro',
            customer: null,
            setCustomerOnly:true,
          }).catch(err => {
            console.log("ctach error: " + err)
          })


          //reload reservation
          this.loadRes();
        });
      })
    },
    showTablesDialog(reservation) {
      this.selected_reservation = {...reservation};
      this.tables_dialog = true;
    },
    async initOrder(reservation) {

      //filter reserved tables (name)
      const reserved_tables = reservation.tables.map((table) => table.name);
      /**
       * check if reserved tables are available or not then apply the logic
       */
      await this.checkAvailableTables(reserved_tables).then((res) => {

        if (Array.isArray(res.unavailable) && res.unavailable.length > 0) {
          this.$swal({
            title: this.$t('generic.lang_unavailableTables'),
            text: res.unavailable.join(' , ') + ' ' + this.$t('generic.lang_areNotAvailableDoYouWantToContinueOnlyWith') + ': ' + res.available.join(' , '),
            icon: "warning",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: this.$t('generic.lang_yes'),
            cancelButtonText: this.$t('generic.lang_no'),
            preConfirm: (val) => {
              if (val)
                this.setGastroOrder(reservation, {
                  name: res.available[0],
                  parties: []
                })
            },
            allowOutsideClick: () => !this.$swal.isLoading,
          });
        } else {
          this.setGastroOrder(reservation, {
            name: res.available[0],
            parties: []
          })
        }

      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err,
          color: "error"
        });
      });
    },
    orderItems() {
      return new Promise((resolve, reject) => {

        try {
          this.loading = true;

          //CALL FUNCTION IN PrintOrderBon.vue Mixin
          this.$refs.printOrderBon.printOrderBons().then(() => {
            this.loading = false;
            resolve(true);
          }).catch(() => {
            this.loading = false;
            resolve(true);
          });


          let openItems = [];
          for (let party in this.pos.gastro.openItems) {
            if (!this.pos.gastro.openItems.hasOwnProperty(party))
              continue;

            this.pos.gastro.openItems[party].forEach((openItem) => {
              openItems.push(openItem);
            });
          }


          //CHECK FISCAL CLIENT
          //CHECK IF WE HAVE OPENITEMS
          if (this.fiscalClient !== null && openItems.length > 0)
            this.startTransaction(openItems);


        } catch (e) {
          this.loading = false;
          reject(e);
        }

      });
    },
    startTransaction(openItems) {
      let tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

      if (tseDevice === null)
        this.tseErrorMessage = this.$t('generic.lang_unknownError') + "...";

      if (tseDevice && !tseDevice.tseReady && tseDevice.connected)
        this.tseErrorMessage = this.$t('generic.lang_tseIsNotOperational');

      // SINGLE CASHIER MODE
      if (tseDevice)
        tseDevice.GFE_StartTransaction(this.fiscalClient.clientID);

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "StartTransaction") {
          if (payload.result.result === "EXECUTION_OK") {
            this.finishTransaction(tseDevice, payload.result.output, openItems);
          } else {
            this.tseErrorMessage = payload.result.result;
          }
        }
      });
    },
    finishTransaction(tseDevice, transaction, openItems) {
      tseDevice.finishOrderbonTransaction(this.fiscalClient.clientID, transaction, openItems);

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "FinishTransaction") {
          if (!payload.result.result === "EXECUTION_OK") {
            this.tseErrorMessage = payload.result.result;
          }
        }
      });
    },
    orderbonReprintFinished() {

    },
    orderbonCancelReprint() {

    }
  }
  ,
  async mounted() {
    this.setToday();
    this.loadTypes();
    this.loadRes();
    this.items = await this.$store.dispatch("items/getItems");
  },

}
</script>

<style scoped>

</style>
