<template>
  <v-dialog v-model="dialog" class="main-card mb-3 card pa-0 ma-0" max-width="800" persistent>
    <v-card class="card-body pa-0">
      <v-card-title class="card-title card-header-tab ">

        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ $t('reservation.lang_printReservation') }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">

          <div class="btn-actions-pane-right actions-icon-btn">
            <v-btn class="elevation-0" color="error"
                   fab
                   small @click="close">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-title>
      <v-divider class="pa-0 ma-0"/>

      <v-card-text class="pb-0 pt-8">
        <v-form ref="form" lazy-validation>
          <v-row>

            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="12">
              <v-select v-model="printerIdChoosen" :disabled="this.loadData"
                        :items="this.printerObj"
                        :label="$t('generic.lang_selectPrinter')" :loading="this.loadData"
                        :rules="[ v => !!v]"
                        dense
                        item-text="name"
                        item-value="id"
                        outlined/>
            </v-col>

          </v-row>
<v-row>
  <v-col class="pt-0 pb-0 mt-0 mb-0" cols="6" sm="6">
  </v-col>

    <v-col class="pt-0 pb-0 mt-0 mb-0" cols="6" sm="6">
      <v-btn
          color="#FF6D00"
          style="color:white;float:right;"
          @click="printInformations()">
        {{ $t('erp.lang_print') }}
      </v-btn>
    </v-col>
</v-row><br><br><br>
        </v-form>
      </v-card-text>
    </v-card>

  </v-dialog>
</template>

<script>

import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {mapState} from "vuex";
import {printDataFromPrinter} from "../../../plugins/printing/printerController";
import {geol} from "@/main";

export default {
  name: "PrintReservationDialog",
  props: {
    default_data: Object,
    dialog: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data: () => {
    return {
      rooms_loader: false,
      types: [],
      loading: false,
      loadData: false,
      show: false,
      printerIdChoosen: 0,

    }
  },
  mounted() {
    // since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it
    // we re gonna use this work arround for now until we find a better way
    if (this.$store.getters['settings/getSettingValue']('geoloc_systemcountry')) {
        geol.locale = this.$store.getters['settings/getSettingValue']('geoloc_systemcountry');
    }
  },

  computed: {
    ...mapState('printer', {
      printerObj: state => state.printers
    }),
    reservationPrinter() {
      //console.log(this.printerObj);
      return this.printerObj.find((printer) => {
        if (printer.id === this.printerIdChoosen) {
          return true;
        }
      });
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },



    printInformations() {
      //SORT ITEMS BY MENU
      let menuData = new Array();
      this.default_data.items.forEach(item => {
        if (!menuData[item.menuTypeName]) {
          menuData[item.menuTypeName] = [];
        }
        menuData[item.menuTypeName].push(item);
      });

      let printData = [];

      if (menuData.length > 0){
        //PRINT INFORMATIONS WITH NOTES WITH MEAL ITEMS
        for (let i in menuData) {

          printData.push({
            feed: 1
          });
          printData.push({
            font: "B"
          });
          printData.push({
            align: "center"
          });

          printData.push({
            fontSize: 2
          });

          printData.push({
            reverse: "on"
          });

          printData.push({
            text: geol.t('generic.lang_info')
          });

          printData.push({
            reverse: "off"
          });

          printData.push({
            feed: 1
          });

          printData.push({
            align: "left"
          });

          const d = new Date(this.default_data.date);
          const month = '' + (d.getMonth() + 1);
          const day = '' + d.getDate();
          const year = d.getFullYear();
          printData.push({
            text: geol.t('generic.lang_day') + ': ' + day + "." + month + "." + year
          });
          printData.push({
            feed: 1
          });
          printData.push({
            text: geol.t('generic.lang_timeFrom') + ': ' + this.default_data.time
          });
          printData.push({
            feed: 1
          });

          if (this.default_data.end_at.length > 1) {
            printData.push({
              text: geol.t('generic.lang_timeUntil') + ': ' + this.default_data.end_at
            });
            printData.push({
              feed: 1
            });
          }
          if(this.default_data.tables[0]['name'] !== undefined) {
            printData.push({
              text: geol.t('generic.lang_table') + ': ' + this.default_data.tables[0]['name']
            });
            printData.push({
              feed: 1
            });
          }
          printData.push({
            text: geol.t('generic.lang_name') + ': ' + this.default_data.name
          });
          printData.push({
            feed: 1
          });
          printData.push({
            text: geol.t('generic.lang_telefone') + ': ' + this.default_data.phone
          });
          printData.push({
            feed: 1
          });
          printData.push({
            text: geol.t('generic.lang_persons') + ': ' + this.default_data.person
          });
          printData.push({
            feed: 1
          });
          if(this.default_data.note !== null) {
            printData.push({
              text: geol.t('generic.lang_notes') + ': ' + this.default_data.note
            });
            printData.push({
              feed: 1
            });
          }
          printData.push({
            feed: 2
          });
          printData.push({
            font: "B"
          });
          printData.push({
            fontSize: 2
          });
          printData.push({
            text: geol.t('generic.lang_menu') + ': ' + i
          });
          printData.push({
            feed: 1
          });
          menuData[i].forEach(item => {
            printData.push({
              text: ' * ' + item.quantity + 'x ' + item.name,
            });
            printData.push({
              text: '----------------',
            });
          });
          printData.push({
            feed: 2
          });
          printData.push({
            cut: true
          });

        }
    }else{
        //JUST PRINT INFORMATIONS WITH NOTES WITHOUT ANY MEAL ITEMS
        printData.push({
          lineSpace: 50
        });
        printData.push({
          feed: 1
        });
        printData.push({
          font: "B"
        });
        printData.push({
          align: "center"
        });

        printData.push({
          fontSize: 2
        });

        printData.push({
          reverse: "on"
        });

        printData.push({
          text: geol.t('generic.lang_info')
        });

        printData.push({
          reverse: "off"
        });

        printData.push({
          feed: 1
        });

        printData.push({
          align: "left"
        });

        const d = new Date(this.default_data.date);
        const month = '' + (d.getMonth() + 1);
        const day = '' + d.getDate();
        const year = d.getFullYear();
        printData.push({
          text: geol.t('generic.lang_day') + ': ' + day + "." + month + "." + year
        });
        printData.push({
          feed: 1
        });
        printData.push({
          text: geol.t('generic.lang_timeFrom') + ': ' + this.default_data.time
        });
        printData.push({
          feed: 1
        });
        if (this.default_data.end_at.length > 1) {
          printData.push({
            text: geol.t('generic.lang_timeUntil') + ': ' + this.default_data.end_at
          });
          printData.push({
            feed: 1
          });
        }
        if(this.default_data.tables[0]['name'] !== undefined) {
          printData.push({
            text: geol.t('generic.lang_table') + ': ' + this.default_data.tables[0]['name']
          });
          printData.push({
            feed: 1
          });
        }
        printData.push({
          text: geol.t('generic.lang_name') + ': ' + this.default_data.name
        });
        printData.push({
          feed: 1
        });
        printData.push({
          text: geol.t('generic.lang_telefone') + ': ' + this.default_data.phone
        });
        printData.push({
          feed: 1
        });
        printData.push({
          text: geol.t('generic.lang_persons') + ': ' + this.default_data.person
        });
        printData.push({
          feed: 1
        });
        if(this.default_data.note !== null) {
          printData.push({
            text: geol.t('generic.lang_notes') + ': ' + this.default_data.note
          });
        }
        printData.push({
          feed: 2
        });
        printData.push({
          cut: true
        });


      }

      // EPSON EPOS PRINT
      printDataFromPrinter(this.reservationPrinter,printData).then(()=>{

      }).catch((err)=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{

      })
    },

  }
}
</script>

<style scoped>
.selected {
  border: 3px solid blue !important;
}
</style>
